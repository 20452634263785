import { render, staticRenderFns } from "./RegisterPanForm.vue?vue&type=template&id=6dafbe9e&scoped=true&"
import script from "./RegisterPanForm.vue?vue&type=script&lang=js&"
export * from "./RegisterPanForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dafbe9e",
  null
  
)

export default component.exports